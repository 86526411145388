<template>
  <div class="center-content">
    <div class="login-box">
      <p class="title has-text-centered">
        <img src="@/assets/logo-sebrae-white.png" alt="Logo" class="logo" />
      </p>
      <div class="box">
        <basic-login-form :toDash="true" />
      </div>
    </div>
  </div>
</template>

<script>
import BasicLoginForm from '@/components/auth/basicLoginForm'

export default {
  name: 'login',
  components: {
    BasicLoginForm
  },
  methods: {
  },
  mounted () {
    this.utils.addRemoveBodyClass('addClass', 'main-pattern')
  },
  unmounted () {
    this.utils.addRemoveBodyClass('removeClass', 'main-pattern')
  }
}
</script>
