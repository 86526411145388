/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { configApi } from './config';
import ApiErrorWithResponse from '@/services/ApiErrorWithResponse';

export default (showToast = true) => {
  const api = axios.create(configApi);

  api.interceptors.response.use(
    (response) => {
      let message = '';
      let isError = false;

      if (response.status === 422) {
        if (response.data.errors) {
          message = 'Erro ao enviar formulário, campos inválidos';
          if (Object.keys(response.data.errors).some((e) => /email/g.test(e))) {
            message = 'Email inválido';
          }
        }
        isError = true;
      }

      if (response.status === 405) {
        message = 'Não encontramos o que procura';
        isError = true;
      }

      if (response.status === 404) {
        message = 'Não encontramos o que procura';
        isError = true;
      }

      if (response.status === 403) {
        message = response.data.message
          ? response.data.message
          : 'Operação não permitida';
        isError = true;
      }

      if (response.status === 401) {
        message = 'Login ou senha inválidos';
        isError = true;
      }

      if (response.status === 400) {
        if (response.data.message) {
          message = response.data.message;
        } else {
          message = 'Requisição inválida';
        }

        isError = true;
      }

      if (isError) {
        return Promise.reject(new ApiErrorWithResponse(message, response));
      }

      return response;
    },
    (error) => {
      let message = '';
      if (!error.status) {
        message = 'Erro ao conectar no servidor';
      }
      showToast
        && Toast.open({
          duration: 5000,
          message,
          position: 'is-top-right',
          type: 'is-danger',
        });

      return Promise.reject(new ApiErrorWithResponse(message, error));
    },
  );

  return api;
};
