<template>
  <div class="center-content">
    <div class="login-box">
      <p class="title has-text-centered">
        <img src="@/assets/logo-sebrae-white.png" alt="Logo" class="logo" />
      </p>
      <div class="box">
        <basic-form :toDash="true"></basic-form>
        <br>
        <a class="is-block has-text-centered" @click="handleClick">
          Precisa de ajuda?
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BasicForm from '@/components/auth/basicForm.vue'
import { loadZenDesk } from '@/utils/helpers'

export default {
  name: 'login',
  components: {
    BasicForm
  },
  methods: {
    handleClick () {
      if (window.zE) {
        window.zE('webWidget', 'open')
        window.zE('webWidget', 'show')
      } else {
        loadZenDesk()
      }
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.utils.addRemoveBodyClass('addClass', 'main-pattern')
  },
  unmounted () {
    this.utils.addRemoveBodyClass('removeClass', 'main-pattern')
  }
}
</script>

<style></style>
