/* eslint-disable func-names */
/* eslint-disable */

const browserSpecs = (function () {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return { name: M[0], version: M[1] };
}());

const utils = {

  toggleClass(el, className) {
    if (!el.classList.contains(className)) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  },

  toggleBodyClass(className) {
    this.toggleClass(document.body, className);
  },

  replaceBodyClass(className) {
    if (!className) return;
    const el = document.body;
    if (!el.classList.contains(className)) {
      const found = el.className.split(' ').some((c) => {
        if (/route-.*/.test(c)) {
          el.classList.remove(c);
          el.classList.add(`route-${className.toString().replace(/\./g, '-')}`);
          return true;
        }
      });
      if (!found) {
        el.classList.add(`route-${className.toString().replace(/\./g, '-')}`);
      }
    }
  },

  addRemoveBodyClass(addRemoveClass, className) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  },

  visible(el) {
    return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
  },

  removeBodyStyle() {
    document.body.removeAttribute('style');
  },

  normalizeGrade(grade, min, max) {
    return ((grade - min) * (10 / (max - min)));
  },

  normalizeProposalName(number, summary, name) {
    return `${number} - ${summary ? `[${summary}]` : ''} ${name}`;
  },
};

export { browserSpecs, utils };
