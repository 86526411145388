<template>
  <form @submit.prevent="validate">
    <b-field
      label="E-mail"
      :type="{ 'is-danger': errors.has('email') }"
      :message="errors.first('email')"
    >
      <b-input type="email" v-model="user.email" name="email" v-validate="'required'"></b-input>
    </b-field>

    <b-field
      label="Senha"
      :type="{ 'is-danger': errors.has('senha') }"
      :message="errors.first('senha')"
    >
      <b-input
        type="password"
        v-model="user.password"
        name="password"
        v-validate="'required'"
        password-reveal
        ref="password"
      ></b-input>
    </b-field>

    <div class="control has-text-centered">
      <button
        type="submit"
        class="button is-primary is-fullwidth"
        :class="{ 'is-loading': isLoading }"
      >
        Entrar
      </button>
    </div>
  </form>
</template>

<script>
import UserService from '@/services/UserService';

export default {
  props: ['toDash'],
  name: 'BasicForm',

  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },
  methods: {
    validate() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) this.authenticate();
        });
    },
    buildQuery(response) {
      let obj = {
        access_token: response.access_token,
        user_name: `${response.user.nome} ${response.user.sobrenome}`,
        user_id: response.user.id,
        user_email: response.user.email,
        role: response.role,
      };

      if (response.escola) {
        obj.escola_id = response.escola.id;
        obj.is_sas = response.escola.is_sas;
      }

      if (response.aluno) {
        (obj.aluno_id = response.aluno.id), (obj.aluno_turma_id = response.aluno.turma_id);
      }

      return this.serializeObjasURL(obj);
    },
    serializeObjasURL(obj) {
      return Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    },
    async authenticate() {
      this.isLoading = true;
      UserService.login(this.user)
        .then((response) => {
          this.isLoading = false;
          window.location.href = `https://app.pontue.com.br/index.html?${this.buildQuery(
            response
          )}`;
        })
        .catch((error) => {
          this.isLoading = false
        });
    },
  },
};
</script>
