import { ToastProgrammatic as Toast } from 'buefy';
import Api from '@/services/Api';
import ApiErrorWithResponse from './ApiErrorWithResponse';

export default {

  createUser(user) {
    return Api().post('/integracoes/sebrae', user)
      .then((response) => response.data,
        (error) => {
          if (error.message) {
            Toast.open({
              duration: 5000,
              message: error.message,
              position: 'is-top-right',
              type: 'is-danger',
            });
          }
          return Promise.reject(new ApiErrorWithResponse(error.message, error.response));
        });
  },
  login(user) {
    return Api().post('/auth/login', user, { 'X-Requested-By': 'AppPontue' })
      .then((res) => res.data, (error) => {
        if (error.message) {
          Toast.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
          });
        }
        return Promise.reject(new ApiErrorWithResponse(error.message, error.response));
      });
  },

};
