<template>
  <b-field
    label="CPF"
    :type="{ 'is-danger': errors.has('cpf') }"
    :message="errors.first('cpf')"
  >
    <b-input
      name="cpf"
      placeholder="000.000.000-00"
      v-model="cpf"
      v-validate="{
        required: true,
        valid: true
      }"
      v-cleave="masks.cpf"
      @input.native="onInput"
    >
    </b-input>
  </b-field>
</template>

<script>
import { verifyCPF } from '@/utils/helpers'
import Cleave from 'cleave.js'
const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}
export default {
  name: 'cpfField',
  directives: { cleave },
  inject: ['$validator'],

  props: {},
  data () {
    return {
      cpf: '',
      value: '',
      rawValue: '',
      masks: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          numericOnly: true
        }
      }
    }
  },
  components: {},
  methods: {
    onInput (event) {
      this.rawValue = event.target._vCleave.getRawValue()
      this.value = event.target._vCleave.getFormattedValue()
    }
  },
  created () {
    this.$validator.extend('valid', {
      getMessage: () => 'CPF inválido',
      validate: () => {
        if (
          (this.cpf.length === 11 || this.cpf.length == 14) &&
          verifyCPF(this.cpf.replace(/[^0-9]/g, ''))
        ) {
          this.$emit('updateCPF', this.cpf.replace(/[^0-9]/g, ''))
          return true
        }
        return false
      }
    })
  }
}
</script>

<style></style>
