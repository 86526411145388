/* eslint-disable no-undef */
import Vue from 'vue';
import Buefy from 'buefy';
import VeeValidate from 'vee-validate';
import Dictionary from '@/validators/dictionary';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/app.scss';
import { utils } from '@/utils/extensions';

Vue.use(Buefy);
Vue.use(VeeValidate, {
  locale: 'pt',
  dictionary: Dictionary,
  useConstraintAttrs: false,
});

Vue.config.productionTip = false;
Vue.prototype.utils = utils;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
