const dictionary = {
  pt: {
    messages: {
      cpf: () => 'CPF inválido.',
      required: () => 'O campo é obrigatório.',
      email: () => 'E-mail inválido.',
      numeric: () => 'Por favor, digite um número.',
      confirmed: () => 'As senhas digitadas não são iguais',
      url: () => 'URL inválida.',
      regex: () => 'O valor digitado tem o formato inválido.',
      integer: () => 'O valor digitado não é um número.',
      max_value: () => 'O valor digitado é inválido.',
      min_value: () => 'O valor digitado é inválido.',
      min: (...args) => `O valor digitado é menor que ${args[1][0]} caracteres`,
      max: (...args) => `Número de caracteres excede ${args[1][0]}`,
      length: () => 'Quantidade de caracteres inválida',
      decimal: 'Digite um número decimal utilizando . ex: 2.5',
    },
  },
};
export default dictionary;
