/* eslint-disable import/prefer-default-export */
export const configApi = {
  baseURL: 'https://api.pontue.com.br', // -> Pontue//
  // baseURL: 'http://127.0.0.1:8000', // -> local
  // baseURL: 'http://10.0.26.84:8000', // -> Local por ip
  // baseURL: 'https://api.test.pontue.com.br', // Test,
  // baseURL: 'http://192.168.0.12:8000/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Requested-By': 'AppPontue',
  },
  validateStatus: (status) => status < 500,
};
