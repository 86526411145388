/* eslint-disable */

export const showUpdateChrome = ($modal) => {
  if (
    document.body.classList.contains('show-modal-chrome')
    && navigator.browserSpecs.name === 'Chrome'
    && navigator.browserSpecs.version < 81
  ) {
    $modal.open(
      `<div class="has-text-white" style="display: flex;flex-direction: column; background-color:#c31c94;">
        <p class="title is-4 has-text-white has-text-centered" style="padding-top: 15px;">Olá, Professor(a)!</p>
        <p style="font-weight: 400; padding: 40px;padding-top: 0px;" class="is-size-5">
          É necessário atualizar o seu navegador Google Chrome, para visualização correta das atividades e das redações. Para isso, assista o vídeo abaixo. Qualquer dúvida, é só entrar em contato com o nosso chat! Grande abraço! Time Pontue
        </p>
        <div class="has-text-centered" style="min-height: 400px;">
          <iframe width="100%" height="400px" src="https://www.youtube.com/embed/3N9mjNOv_0k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="min-height: 400px;"></iframe>
        </div>
      </div>`,
    );
  }
};

export const normalizeProposalName = (number, summary, name) => `${number} - ${summary ? `[${summary}]` : ''} ${name}`;

export const normalizeStringForSearch = (string) => {
  const input = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
  const output = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
  let newStr = '';
  let change = false;
  for (let i = 0; i < string.length; i++) {
    change = false;
    for (let a = 0; a < input.length; a++) {
      if (string.substr(i, 1) === input.substr(a, 1)) {
        newStr += output.substr(a, 1);
        change = true;
        break;
      }
    }
    if (change === false) {
      newStr += string.substr(i, 1);
    }
  }
  return newStr.toLowerCase();
};

export const addScriptToHeadHTML = (
  type = 'text/javascript',
  src = '',
  id = '',
  onload = function () {},
) => {
  const script = document.createElement('script');
  script.setAttribute('type', type);
  script.setAttribute('src', src);
  script.setAttribute('id', id);

  script.onload = onload;
  document.head.appendChild(script);
};

export const loadJitsi = (callback) => {
  const jitSi = document.getElementById('jitsi-snippet');
  if (!jitSi) {
    const src = 'https://meet.jit.si/external_api.js';
    const idJItsi = 'jitsi-snippet';
    addScriptToHeadHTML('text/javascript', src, idJItsi, callback);
  } else {
    callback();
  }
};

export const loadZenDesk = () => {
  const idZendesk = 'ze-snippet';
  const src = `https://static.zdassets.com/ekr/snippet.js?key=${
    window.location.hostname.split('.')[0] === 'eneuron'
      ? '4d527550-ca0c-4870-a4e1-adcdd489ed33'
      : '5e637133-1277-4b64-b211-16977443e986'
  }`;
  // eslint-disable-next-line no-inner-declarations
  function loadScriptZenDesk() {
    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide');
    });

    window.zE('webWidget:on', 'open', () => {
      const style = document.createElement('style');
      style.innerHTML = `
              #Embed form > div > div:first-child > div:nth-child(1){
                display: none;
              }
              #Embed form > div > div:first-child > div:nth-child(2){
                display: none;
              }
              #Embed > div > div > div > div > div > div > form > div:nth-child(3){
                display: none;
              }
              `;
      const widget = parent.document.getElementById('webWidget');
      if (widget) {
        widget.contentDocument.head.appendChild(style);
      }
    });
    if (localStorage.getItem('auth_token')) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: [
              { id: 360031207111, prefill: { '*': localStorage.getItem('school') } },
              { id: 'name', prefill: { '*': localStorage.getItem('user_name') } },
              { id: 'email', prefill: { '*': localStorage.getItem('user_email') } },
            ],
          },
        },
      });
    }
    window.zE('webWidget', 'open');
  }
  addScriptToHeadHTML('text/javascript', src, idZendesk, loadScriptZenDesk);
};

export const getVisibility = (options, key) => options.includes(key);

export const downloadDataAsXLS = (data, filename = 'export', ext = 'xls') => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = `data:application/vnd.ms-excel,${encodeURIComponent(data)}`;
  link.download = `${filename}.${ext}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isArray = (obj) => !!obj && obj.constructor === Array;

export function mapFields(options) {
  const object = {};
  for (let x = 0; x < options.fields.length; x++) {
    const field = [options.fields[x]];
    object[field] = {
      get() {
        return this.$store.state[options.module][options.base][field];
      },
      set(value) {
        this.$store.commit(options.mutation, { [field]: value });
      },
    };
  }
  return object;
}


export function verifyCPF(cpf) {
  let Soma;
  let Resto;
  Soma = 0;
  let strCPF = String(cpf);
  // strCPF  = RetiraCaracteresInvalidos(strCPF,11);
  if (strCPF == "00000000000" ||
    strCPF == "11111111111" ||
    strCPF == "22222222222" ||
    strCPF == "33333333333" ||
    strCPF == "44444444444" ||
    strCPF == "55555555555" ||
    strCPF == "66666666666" ||
    strCPF == "77777777777" ||
    strCPF == "88888888888" ||
    strCPF == "99999999999" )
    return false;
  for ( let i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;
  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;
  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}
